/* eslint-disable no-param-reassign */
import { Cookies } from 'react-cookie'
// import humps from 'humps'

import { createReducer } from '_utils/redux'
import { User } from '_models'
import { COOKIES_OPTIONS } from '_utils/constants'

import {
  LOGIN,
  GET_USER,
  AUTHENTICATE_TOKEN,
  UPDATE_USER,
  GET_VERIFICATION_TOKEN,
  SET_AUTH_TOKEN,
  UPDATE_USER_STATE,
} from './actions'
import { VIEW_TIP } from '../tip/actions'

const INITIAL_STATE = new User()
const cookies = new Cookies()

export default createReducer(INITIAL_STATE, {
  [LOGIN.FULFILLED]: (state, { payload }) => {
    const { access, refresh, ...user } = payload
    cookies.set(COOKIES_OPTIONS.authTokenName, access, { path: COOKIES_OPTIONS.path })

    if (refresh)
      cookies.set(COOKIES_OPTIONS.refreshTokenName, refresh, { path: COOKIES_OPTIONS.path })

    if (user?.getRoles.includes(',')) {
      user.getRoles = user?.getRoles.split(',')
    }

    return state.mergeProps({ ...user, authToken: access })
  },
  [GET_USER.FULFILLED]: (state, { payload }) => {
    if (payload.getRoles.includes(',')) {
      payload.getRoles = payload.getRoles.split(',')
    }
    return state.mergeProps(payload)
  },
  [UPDATE_USER.FULFILLED]: (state, { payload }) => {
    if (payload.getRoles.includes(',')) {
      payload.getRoles = payload.getRoles.split(',')
    }
    return state.mergeProps(payload)
  },
  [UPDATE_USER_STATE]: (state, { payload }) => {
    return state.mergeProps(payload)
  },
  [AUTHENTICATE_TOKEN.FULFILLED]: (state, { payload }) => {
    cookies.set(COOKIES_OPTIONS.name, payload.key, { path: COOKIES_OPTIONS.path })

    if (payload.user.getRoles.includes(',')) {
      payload.user.getRoles = payload.user.getRoles.split(',')
    }
    return state.mergeProps({ ...payload.user, authToken: payload.key })
  },
  [GET_VERIFICATION_TOKEN.FULFILLED]: (state, { payload }) => {
    return state.mergeProps({ verificationToken: payload.results[0].verificationToken })
  },
  [SET_AUTH_TOKEN]: (state, { payload }) => {
    return state.mergeProps({ authToken: payload })
  },
  [VIEW_TIP.FULFILLED]: (state, { payload }) =>
    state.mergeProps({
      ...payload.user,
      tips: state?.tips?.filter(tip => tip?.id !== payload?.tipId),
    }),
})
